/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import AppForm from '@/components/Form/AppForm.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Map from '@/assets/js/Map/Map'
import TabButton from '@/assets/js/Tab/TabButton'
import Header from '@/assets/js/Header/Header'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonOrder from '@/assets/js/Button/Order/ButtonOrder'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    new SwiperSlider()
    new Map()
    new TabButton()
    new Header()
    new ButtonTop()
    new ButtonOrder()
})
