<template lang="pug">
.form
  .form__block(v-show="!sendForm")
    .form__fields
      .form__field
        .input-text.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-field-user")

      .form__field
        .input-text.input-text-icon(:class="{'input-text-error': fieldError && v$.$invalid}")
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-field-phone")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button
      .button.button-background(
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")

  .form__block(v-show="sendForm")
    h4(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldProduct",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldProduct,
              value: $(`.button--order-active`).data('name')
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
        this.fieldError = false

        setTimeout(() => {
          this.sendForm = !this.sendForm
          $('.is-close-btn').click()
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
